var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sabc_featured_area"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12"},[_c('div',{staticClass:"sabc_main_featured"},[(_vm.topStories[0].link.startsWith('http'))?_c('a',{attrs:{"href":_vm.topStories[0].link,"target":"_blank"}},[_c('img',{attrs:{"srcset":[
                                `${_vm.$resizeImage(_vm.topStories[0].image.image_path, 798, 554)} 991w`,
                                `${_vm.$resizeImage(_vm.topStories[0].image.image_path, 640, 534)} 768w`,
                                `${_vm.$resizeImage(_vm.topStories[0].image.image_path, 320, 267)} 480w`
                            ].join(', '),"sizes":'(max-width: 480px) 480w, (max-width: 768px) 768w, (max-width: 991px) 991w',"alt":_vm.topStories[0].image.caption || _vm.topStories[0].image.file_name,"title":_vm.topStories[0].image.caption || _vm.topStories[0].image.file_name}}),_vm._v(" "),_c('div',{staticClass:"sabc_main_featured_dtl"},[_c('div',{staticClass:"sabc_main_featured_desc"},[(_vm.topStories[0].is_live)?_c('label',[_vm._v("WATCH LIVE")]):_c('label',{staticClass:"ts-main-label"},[_vm._v("Top Stories")]),_vm._v(" "),_c('h1',[(_vm.topStories[0].is_live)?_c('span',[_c('img',{attrs:{"src":require('@/assets/images/sabc_live_icon.webp'),"alt":"sabc_live_icon"}}),_vm._v("\n                                       LIVE\n                                    ")]):_vm._e(),_vm._v("\n                                    "+_vm._s(_vm.topStories[0].headline)+"\n                                ")])])])]):_c('router-link',{attrs:{"to":_vm.topStories[0].link}},[_c('img',{attrs:{"srcset":[
                                `${_vm.$resizeImage(_vm.topStories[0].image.image_path, 798, 554)} 991w`,
                                `${_vm.$resizeImage(_vm.topStories[0].image.image_path, 640, 534)} 768w`,
                                `${_vm.$resizeImage(_vm.topStories[0].image.image_path, 320, 267)} 480w`
                            ].join(', '),"sizes":'(max-width: 480px) 480w, (max-width: 768px) 768w, (max-width: 991px) 991w',"alt":_vm.topStories[0].image.caption || _vm.topStories[0].image.file_name,"title":_vm.topStories[0].image.caption || _vm.topStories[0].image.file_name}}),_vm._v(" "),_c('div',{staticClass:"sabc_main_featured_dtl"},[_c('div',{staticClass:"sabc_main_featured_desc"},[(_vm.topStories[0].is_live)?_c('label',[_vm._v("WATCH LIVE")]):_c('label',{staticClass:"ts-main-label"},[_vm._v("Top Stories")]),_vm._v(" "),_c('h1',[(_vm.topStories[0].is_live)?_c('span',[_c('img',{attrs:{"src":require('@/assets/images/sabc_live_icon.webp'),"alt":"sabc_live_icon"}}),_vm._v("\n                                       LIVE\n                                    ")]):_vm._e(),_vm._v("\n                                "+_vm._s(_vm.topStories[0].headline)+"\n                                ")])])])])],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12"},_vm._l((_vm.topStories.slice(1)),function(item){return _c('div',{key:item.id,staticClass:"sabc_sub_featured"},[(item.link.startsWith('http'))?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{attrs:{"srcset":[
                                `${_vm.$resizeImage(item.image.image_path, 400, 248)} 400w`,
                                `${_vm.$resizeImage(item.image.image_path, 359, 248)} 359w`,
                                `${_vm.$resizeImage(item.image.image_path, 270, 114)} 270w`,
                                `${_vm.$resizeImage(item.image.image_path, 147, 114)} 147w`
                            ].join(', '),"sizes":'(max-width: 480px) 147w, (max-width: 575px) 270w, (max-width: 768px) 359w, (max-width: 991px)  400w',"alt":item.image.caption || item.image.file_name,"title":item.image.caption || item.image.file_name}}),_vm._v(" "),_c('div',{staticClass:"sabc_sub_featured_dtl"},[_c('div',{staticClass:"sabc_sub_featured_desc"},[_c('label',[_vm._v("Top Stories")]),_vm._v(" "),_c('h2',[_vm._v(_vm._s(item.headline))])])])]):_c('router-link',{attrs:{"to":item.link}},[_c('img',{attrs:{"srcset":[
                                `${_vm.$resizeImage(item.image.image_path, 400, 248)} 400w`,
                                `${_vm.$resizeImage(item.image.image_path, 359, 248)} 359w`,
                                `${_vm.$resizeImage(item.image.image_path, 270, 114)} 270w`,
                                `${_vm.$resizeImage(item.image.image_path, 147, 114)} 147w`
                            ].join(', '),"sizes":'(max-width: 480px) 147w, (max-width: 575px) 270w, (max-width: 768px) 359w, (max-width: 991px)  400w',"alt":item.image.caption || item.image.file_name,"title":item.image.caption || item.image.file_name}}),_vm._v(" "),_c('div',{staticClass:"sabc_sub_featured_dtl"},[_c('div',{staticClass:"sabc_sub_featured_desc"},[_c('label',[_vm._v("Top Stories")]),_vm._v(" "),_c('h2',[_vm._v("\n                                    "+_vm._s(item.headline)+"\n                                ")])])])])],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }