<template>
    <!-- <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div> -->
    <ListingShimmer v-if="!is_data_ready"></ListingShimmer>
    <div v-else-if="is_data_ready">
        <!-- featured area start -->
        <TopStories v-if="topStories.length" :topStories="topStories"></TopStories>
        <!-- featured area end -->
        <!-- Content Start -->
        <section class="sabc_tier_sports_sec">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <TopTireNews v-if="topTireNews" :tournamentNames = "topTireNews"></TopTireNews>
                    </div> 
                    <SideBar :editorsPick="editorsPick" :olympicsTable="olympicsTable" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
                </div>
            </div>
        </section>
        <!--  Content end -->
    </div>
</template>

<script>
    import LatestNews from '@/components/common/latest-news'
    import TopStories from '@/components/common/top-stories'
    import SportOptions from '@/components/common/sport-options'
    import KeySeries from '@/components/key-series/key-series'
    import SideBar from '@/components/common/sidebar/sidebar';
    import TournamentNews from '@/components/Sports/tournament-news';
    import TopTireNews from '@/components/Sports/toptire-news';
    import ListingShimmer from '@/components/common/shimmer/listing-shimmer';

    export default {
        name: "home",
        data() {
            return {
                url: '',
                tournamentNews: [],
                topTireNews: [],
                latestNews: [],
                topStories: [],
                editorsPick: [],
                section: [],
                olympicsTable: '',
                sport_name: '',
                offset : 1,
                bannerImages : [],
                bannerEmbed : [],
                paginate : [],
                is_data_ready: false
            }
        },
        components: {
            LatestNews, TopStories, SportOptions, KeySeries, SideBar, TournamentNews, TopTireNews, ListingShimmer
        },
        mounted() {
            this.getSportsData(this.offset);
        },
        serverPrefetch() {
            return this.getSportsData(this.offset);
        },
        watch: {
            '$route': function (to, from) {
                this.latestNews = [];
                this.offset = 1;
                this.tournamentNews = null;
                this.getSportsData(this.offset);
            }
        },
        metaInfo() {

           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;
                let sportKey = (this.$route.params.sport).replace(/-/g, '_');

                var replacements = {
                    "%SECTION_TILTE%": this.section.meta_title,
                    "%SECTION_DESCRIPTION%": this.section.meta_description,
                    "%SECTION_KEYWORD%": this.section.meta_keywords,
                    "%SPORT%": this.$route.params.sport
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss[sportKey+'_title'],replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss[sportKey+'_meta_description'],replacements));
                let keywords = this.$getSafe(() => this.$replacePlaceHolders(ss[sportKey+'_meta_keywords'],replacements));

                return {
                    title: (title) ? title : 'Latest Sport News, Reports and Latest Trends - SABC Sport',
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        methods: {
            getSportsData(offset) {
                if(this.$route.params.sport == 'football'){
                   return this.$router.push({ name: 'sport_home', params: {sport:'soccer', news: 'news' } })
                }
                this.offset = offset;
                this.sport_name = this.$route.params.sport;
                this.url = this.$GetSportsData + '?sport=' + this.sport_name + '&page=' + offset;
                return this.$axios.get(this.url)
                        .then(response => {
                            if (response.data) {
                                this.topStories = response.data.topStories;
                                this.editorsPick = response.data.editorsPick;
                                this.olympicsTable = response.data.olympicsTable;
                                this.topTireNews = response.data.topTireNews;
                                this.section = response.data.section;
                                this.bannerImages = response.data.bannerImages;
                                this.bannerEmbed = response.data.bannerEmbed;
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },

        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/featured.scss';
@import '~@/assets/scss/tier_sports.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>
