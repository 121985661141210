<template>
    <div>
        <div id="matches" >
            <div class="DivMatchList"  >
                <div v-for="(tournament, index) in tournaments" :key="index" class="ft_lc_list" >
                    <h2>
                        <router-link :to="{name: 'football_league_detail', params: {league: $slugify(tournament.name), leagueId: tournament.id ,date: $route.params.date, matchType: 'fixtures'}}"><!-- [routerLink]="['/'+apis.urlKeyword+'/league-detail/'+slugifyPipe.transform(matches.ContestGroupNameEnglish)+'/'+matches.contest_groups + '/' + this.datePipe.transform(selectedDate, 'yyyy-MM-dd')]"-->
                            {{tournament.name}}
                        </router-link>
                    </h2>
                    <div v-for="(match, index1) in tournament.matches" :key="index1" class="ft_lc_row">
                        <div class="ft_lc_time">
                            <!-- <span :class="match.statusClass">{{match.shortStatus}}</span> -->
                            <div class="live_center_fixtured_timeNew">
                                <span
                                v-if="match.minutes"
                                :class="match.statusClass"
                                >{{ match.minutes }}</span
                                >
                                <span
                                v-else
                                :class="match.statusClass"
                                >{{ match.shortStatus }}</span
                                >
                            </div>
                        </div>
                        <div class="ft_lc_rslt">
                            <router-link :to="{ name: 'match_detail.overview', params: { slug: $slugify(tournament.name + ' ' + $getSafe(()=>$filterTeam(match.competitors, true).name) + ' vs ' + $getSafe(()=>$filterTeam(match.competitors, false).name)), date: $route.params.date, matchId: match.id } }">
                                <div class="ft_lc_name">
                                        <span  class="ft_lc_hm_tname">{{$getSafe( () => $filterTeam(match.competitors, true).name)}}</span>
                                        <span class="ft_lc_hm_tname_mbl">{{$getSafe( () => $filterTeam(match.competitors, true).name)}}</span>
                                        <img class="ft_lc_hm_pic" :onerror="$teamDefaultLogo()" :src="$storageURL+'images/football/'+$getSafe( () => $filterTeam(match.competitors, true).id)+'_90x90.webp'" :alt="$getSafe( () => $filterTeam(match.competitors, true).name)+' Team Logo'" loading="lazy">
                                </div>
                                <div class="ft_lc_score">
                                    <h6 :class="match.scoreClass">{{match.score}}</h6>
                                </div>
                                <div class="ft_lc_name">
                                        <img class="ft_lc_awy_pic" :onerror="$teamDefaultLogo()" :src="$storageURL+'images/football/'+$getSafe( () => $filterTeam(match.competitors, false).id)+'_90x90.webp'" :alt="$getSafe( () => $filterTeam(match.competitors, false).name)+' Team Logo'" loading="lazy">
                                        <span  class="ft_lc_awy_tname">{{$getSafe( () => $filterTeam(match.competitors, false).name)}}</span>
                                        <span  class="ft_lc_awy_tname_mbl">{{$getSafe( () => $filterTeam(match.competitors, false).name)}}</span>
                                </div>
                            </router-link>
                        </div>
<!--                            <div class="icon-live">-->
<!--                                <router-link :to="'/live/football/match-detail/international-africa-cup-of-nations-qualification-group-f-uganda-vs-niger/2022-06-08/2115843/commentary'">-->
<!--                                    <img  alt="image description" :src="require('@/assets/images/icon-live.webp')">-->
<!--                                </router-link>-->
<!--                            </div>-->
                        <div class="ft_lc_fav" title="Add to my matches">
                            <i :class="($store.getters.isFbMatchFavourited(match.id)) ? 'text-green' : '1'" class="fa fa-star" @click="toggleFavourite(match.id)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "MatchCard",
        props: ['tournaments'],

        data() {
            return {}
        },
        mounted() {
        },
        jsonld() {

            if (!this.tournaments)
                return

            let tournaments = Object.values(this.tournaments).flat(1) || [];
            let events = [];


            (this.tournaments.forEach((tournament)=>{

                ((tournament.matches).forEach((match)=>{

                    let competitor1 = '';
                    let competitor2 = '';
                    // Extract Teams
                    ((match.competitors).forEach((competitor)=>{
                        if(competitor.isHome == true) {
                            competitor1 = competitor.name;
                        } else {
                            competitor2 = competitor.name;
                        }
                    }));

                    events.push(
                        {
                            "@type": "SportsEvent",
                            "name": competitor1 + " VS " + competitor2,
                            "description": tournament.name + " - " + competitor1 + " VS " + competitor2 + " - " + match.round,
                            "url": this.$siteURL + "live/football/match-detail/" + this.$slugify(tournament.name +" "+ competitor1 + " VS " + competitor2) +'/'+ this.$route.params.date +'/'+ match.id +'/overview',
                            "eventStatus": "https://schema.org/EventScheduled",
                            "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                            "startDate": moment(match.date).format('YYYY-MM-DD HH:MM'),
                            "endDate": moment(match.date).format('YYYY-MM-DD'),
                            "image": this.$siteURL + 'public/images/logo.png',
                            "organizer": {
                                "@type": "Organization",
                                "name": tournament.name,
                                "url": this.$siteURL + "live/football/league-detail/" + this.$slugify(tournament.name) +'/'+ tournament.id +'/'+ this.$route.params.date,
                            },
                            "offers": {
                                "@type": "Offer",
                                "name": competitor1 + " VS " + competitor2,
                                "url": this.$siteURL + "live/football/match-detail/" + this.$slugify(tournament.name +" "+ competitor1 + " VS " + competitor2) +'/'+ this.$route.params.date +'/'+ match.id +'/overview',
                                "priceCurrency": "EUR",
                                "validFrom": moment(match.date).format('YYYY-MM-DD'),
                                "availability": "https://schema.org/InStock",
                                "price": "0"
                            },
                            "performer": {
                                "@type": "Thing",
                                "name": competitor1 + " VS " + competitor2,
                            },
                            "competitor": [
                                {
                                    "@type": "SportsTeam",
                                    "name": competitor1,
                                    "image": this.$siteURL + 'public/images/logo.png',
                                },
                                {
                                    "@type": "SportsTeam",
                                    "name": competitor2,
                                    "image": this.$siteURL + 'public/images/logo.png',
                                }
                            ],
                            "location": {
                                "@type": "Place",
                                "name": tournament.name,
                                "address": {
                                    "@type": "PostalAddress",
                                    "name": '',
                                }
                            }
                        }
                    )
                }));
            }));

            return {
                "@context": "https://schema.org",
                "@graph": events
            };

        },

        methods: {
            toggleFavourite(id) {
                this.$store.commit(this.$store.getters.isFbMatchFavourited(id) ? 'removeFootballFavouriteMatch' : 'addFootballFavouriteMatch', id);
                if (this.$route.name == 'football.myMatches') {
                    var match_list = this.tournaments;
                    var match_id = id;
                    this.tournaments.forEach(function (val, key) {
                        val.matches.forEach(function (val_a, key_a) {
                            //if given match id exists in matchList.matches array then remove that match
                            if(val_a.id == id){
                                val.matches.splice(key_a, 1);
                            }
                            //if given matchList.matches contain no match then remove that record(contest group data) from matchList
                            if (val.matches.length < 1){
                                match_list.splice(key, 1);
                            }
                        });
                    });
                }
            },
        }
    }
</script>
