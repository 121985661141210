<template>
    <section class="container">
        <div class="sabc_video_section home-page-widget" v-if="videos.length">
            <div class="sabc_video_tabs_btn">
                <div class="sabc_video_tabs">
                    <h2>
                        Catch up
                    </h2>
                </div>
                <router-link :to="{ name: 'videolising'}" class="sabc_video_btn">
                    SEE ALL
                </router-link>
            </div>
            <div class="sabc_video_list swiper video_swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="video in videos" :key="video.id">
                        <ul>
                            <li>
                                <router-link :to="{ name: 'video_player', params: { category: video.feed_source, player: video.slug}}">
                                    <div class="sabc_video_listpic">
                                        <picture>
                                            <source media="(min-width: 575px)" :srcset="$storageURL+'images/video/'+video.feed_url+'_307x166.webp'">
                                            <img :src="$storageURL+'images/video/'+video.feed_url+'_231x166.webp'" width="444" height="249" :alt="video.title" :title="video.title" loading="lazy">
                                        </picture>
                                        <span>
                                            <img src="/assets/img/sabc_video_list_play_icon.png" alt="SABC Play Icon" title="SABC Play Icon" loading="lazy" width="40" height="40" />
                                        </span>
                                        <label>{{new Date(video.duration*1000).toISOString().slice(11, 19)}}</label>
                                    </div>
                                    <div class="sabc_video_listdesc">
                                        <b>{{video.title}}</b>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import {Swiper} from "swiper";

    export default {
        name: 'VideoSlider',
        props: ['videos'],
        data() {
            return {}
        },
        components: {},
        mounted() {
            new Swiper('.video_swiper', {
                slidesPerView: 3.5,
                observer: true,
                observeParents: true,
                paginationClickable: true,
                spaceBetween: 16,
                autoplay: false,

                breakpoints: {
                    1199: {
                        slidesPerView: 3.5,
                        spaceBetween: 24
                    },
                    1000: {
                        slidesPerView: 2.5,
                        spaceBetween: 24
                    },
                    767: {
                        slidesPerView: 2.5,
                        spaceBetween: 16
                    },
                    400: {
                        slidesPerView: 1.5,
                        spaceBetween: 16
                    },
                    200: {
                        slidesPerView: 1.1,
                        spaceBetween: 16
                    }
                }
            });
        },
        serverPrefetch() {
        },
        methods: {
        },
    }
</script>
