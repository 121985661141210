<template>
    <div>
        <div class="sabc_tier_news_tabs">
            <!-- <h2>Competitions</h2> -->
            <div class="sabc_tier_news_tabs">
                <h2 v-if="$route.params.sport == 'football'">Soccer News</h2>
                <h2 v-else>{{$route.params.sport.toLowerCase().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}} News</h2>
            </div>
            <ul>
                <li>
                    <router-link :class="('All' == selectedTab) ? 'selected' : ''" :to="{name:'sport_home', params: {news:'news', sport: this.$route.params.sport}}">
                        <span>All</span>
                    </router-link>
                </li>
                <li v-for="tournamentName in tournamentNameList" :key="tournamentName.id">
                    <router-link :class="(tournamentName.slug == selectedTab) ? 'selected' : ''" :to="{name:'sport_home_competition', params: {news: 'news', competitions: 'competitions', competition:tournamentName.slug}}">
                        <span v-if="tournamentName.name == 'Premier Soccer League'">DSTV Premiership</span>
                        <span v-else>{{showTournamentName(tournamentName.name)}}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <!-- <div class="text-center" v-if="!is_data_ready">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> -->
        <ListingShimmer v-if="!is_data_ready"></ListingShimmer>
        <div class="sabc_tier_news_sec" v-if="is_data_ready">
            <div class="sabc_tier_news_row" v-for="article in allNews" :key="article.id">
                <div class="sabc_tier_news_pic">
                    <router-link v-if="article" :to="article.articleSection.section.slug+article.slug">
                        <img 
                            :srcset="[
                                $resizeImage(article.image.image_path, 368, 232) + ' 991w',
                                $resizeImage(article.image.image_path, 144, 91) + ' 768w'
                            ].join(', ')"
                            :sizes="'(max-width: 768px) 768w, (max-width: 991px) 991w'"
                            width="368" 
                            height="232" 
                            :alt="article.headline" 
                            :title="article.headline">
                    </router-link>
                </div>
                <div class="sabc_tier_news_desc">
                    <h2>
                        <router-link v-if="article" :to="article.articleSection.section.slug+article.slug">
                            {{article.headline}}
                        </router-link>
                    </h2>
                    <p v-if="article" v-html="article.stand_first_simple">
                    </p>
                    <label v-if="article">
                        {{article.start_date | formatDate2}}
                    </label>
                </div>
            </div>
            <div v-if="paginate['total'] == 0 ">
                No Data Found.
            </div>
            <div class="d-flex justify-content-center mb-20" v-if="is_data_ready && paginate['total']">
                <button @click="getTournamentData(offset+1)" class="btn btn-primary mb-5 mt-2" :class="[{'invisible' : (paginate['total'] == 0 || paginate['current_page'] == Math.ceil(paginate['total']/8)) }]">Load More</button>
            </div>
        </div>
    </div>
</template>
<script>
    import ListingShimmer from '@/components/common/shimmer/listing-shimmer';
    export default {
        name: 'TopTireNews',
        props: ['tournamentNames'],
        data() {
            return {
                allNews: [],
                selectedTab: 'All',
                tournamentNews: [],
                tournamentNameList: [],
                paginate: [],
                offset: 1,
                is_data_ready: false
            }
        },
        jsonld() {
            if (isServer)
                return {
                    "@context": "https://schema.org",
                    "@graph": []
                }

            if (!this.allNews)
                return

            let events = [];
            this.allNews.forEach((article) => {
                let path = this.$siteURL.replace(/\/+$/, '') + article.articleSection.section.slug+article.slug;

                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': path
                        },
                        headline:  article.headline,
                        description: article.meta_description,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: article.author.display_name,
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: '',
                        dateModified: ''
                    }
                )
            });
            return {
                "@context": "https://schema.org",
                "@graph": events
            };
        },
        components: {
            ListingShimmer
        },
        serverPrefetch() {
            this.getTournamentData(this.offset);
        },
        watch: {
            '$route': function (to, from) {
                this.allNews = [];
                this.offset = 1;
                this.tournamentNews = null;
                this.getTournamentData(this.offset);
                this.is_data_ready = false;
            }
        },
        mounted() {
            this.offset = 1;
            this.allNews = [];
            this.getTournamentData(this.offset);
            this.is_data_ready = false;
        },
        methods: {
            getTournamentInfo(category){
                if(category != this.selectedTab ){
                    this.selectedTab = category;
                    this.offset = 1;
                    this.allNews = [];
                    this.getTournamentData(this.offset);
                    this.is_data_ready = false;
                }
            },
            getTournamentData(offset){
                this.offset = offset;
                this.sport_name = this.$route.params.sport;
                this.selectedTab = (this.$route.params.competition)?this.$route.params.competition:'All';
                this.url = this.$GetTournamentData + '?sport=' + this.sport_name +'&tounamentSlug=' + this.selectedTab + '&page=' + offset;
                return this.$axios.get(this.url)
                    .then(response => {
                        if (response.data) {
                            this.allNews = this.allNews.concat(response.data.latestNewsAll.data);
                            this.tournamentNameList = response.data.topTireNews;
                            this.paginate['total'] = response.data.latestNewsAll.total;
                            this.paginate['current_page'] = response.data.latestNewsAll.current_page;
                            this.is_data_ready = true;
                        }
                    })
                    .catch(error => {
                    })
            },
            showTournamentName(tournamentName){
                if(tournamentName == 'Olympics Other' || tournamentName == 'Olympics Athletics' || tournamentName == 'Olympics Swimming'){
                    return tournamentName.replace('Olympics ', '');
                }else{
                    return tournamentName;
                }
            }
        }
    }
</script>
