<template>
<!-- Shimmer Loader HTML for Featured Area and News Section -->
<section class="sabc_featured_area sabc_news_section sabc_news_nhtopstory">
  <div class="container">
    <div class="row">
      
      <!-- Featured Section Shimmer (Main Featured Item) -->
      <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="sabc_main_featured shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content">
            <div class="shimmer-image shinner-main-box"></div>
          </div>
        </div>
      </div>

      <!-- Featured Section Shimmer (Sub Featured Items) -->
      <div class="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <div class="sabc_sub_featured shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content shimmer-sub-box">
            <div class="shimmer-image"></div>
            <div class="shimmer-title" style="width: 100%;"></div>
            <div class="shimmer-title" style="width: 90%;"></div>
            <div class="shimmer-title" style="width: 80%;"></div>
            <div class="shimmer-desc"></div>
          </div>
        </div>
        <div class="sabc_sub_featured shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content shimmer-sub-box">
            <div class="shimmer-image"></div>
            <div class="shimmer-title" style="width: 100%;"></div>
            <div class="shimmer-title" style="width: 90%;"></div>
            <div class="shimmer-title" style="width: 80%;"></div>
            <div class="shimmer-desc"></div>
          </div>
        </div>
      </div>

      <!-- News Section Shimmer (News Boxes) -->
      <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <div class="sabc_news_box shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content">
            <div class="shimmer-image"></div>
            <div class="shimmer-title"></div>
            <div class="shimmer-desc"></div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <div class="sabc_news_box shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content">
            <div class="shimmer-image"></div>
            <div class="shimmer-title"></div>
            <div class="shimmer-desc"></div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <div class="sabc_news_box shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content">
            <div class="shimmer-image"></div>
            <div class="shimmer-title"></div>
            <div class="shimmer-desc"></div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
        <div class="sabc_news_box shimmer-loader">
          <div class="shimmer-box"></div>
          <div class="shimmer-content">
            <div class="shimmer-image"></div>
            <div class="shimmer-title"></div>
            <div class="shimmer-desc"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
</template>

<script>
    export default {
        name: 'home-shimmer',
    }
</script>
<style lang="scss" scoped >
  .shimmer-loader{position:relative;overflow:hidden;display:flex;flex-direction:column}.shimmer-box{width:100%;background-color:#e0e0e0;animation:1.5s linear infinite shimmer;background:linear-gradient(to right,#f0f0f0 8%,#ecebeb 18%,#f0f0f0 33%);background-size:200% 100%;position:relative}.shimmer-image,.shimmer-title{margin-bottom:10px;background-color:#f0f0f0;animation:1.5s linear infinite shimmer}.shimmer-content{position:relative;z-index:1}.shimmer-image{width:100%;background:linear-gradient(to right,#f0f0f0 8%,#ecebeb 18%,#f0f0f0 33%);background-size:200% 100%}.shimmer-image.shinner-main-box{height:267px;margin-bottom:15px}.sabc_sub_featured{height:230px}.sabc_sub_featured .shimmer-image{height:114px;border-radius:8px}.sabc_news_box{flex-direction:row;gap:15px;align-content:flex-start;align-items:flex-start}.sabc_news_box .shimmer-content{width:calc(100% - 159px)}.sabc_news_box .shimmer-box{height:91px;width:144px;border-radius:8px}.shimmer-title{width:80%;height:20px;background:linear-gradient(to right,#f0f0f0 8%,#ecebeb 18%,#f0f0f0 33%);background-size:200% 100%}.shimmer-desc{width:60%;height:15px;background-color:#f0f0f0;animation:1.5s linear infinite shimmer;background:linear-gradient(to right,#f0f0f0 8%,#ecebeb 18%,#f0f0f0 33%);background-size:200% 100%}@keyframes shimmer{0%{background-position:-200% 0}100%{background-position:200% 0}}.row{display:flex;flex-wrap:wrap}@media screen and (min-width:992px){.shimmer-loader{flex-direction:row;border-radius:8px;margin-bottom:27px}.sabc_main_featured,.sabc_sub_featured{opacity:.5}.sabc_sub_featured{height:267px;border-radius:8px;margin-bottom:15px}.sabc_news_box{flex-direction:column;gap:15px;align-content:flex-start;align-items:flex-start}.sabc_news_box .shimmer-box{height:188px;width:100%}.sabc_news_box .shimmer-content{width:100%}}@media (max-width:768px){.shimmer-title{width:70%;height:15px}.shimmer-desc{width:50%;height:12px}}
</style>