<template>
    <section class="sabc_social_section">
        <div class="container swiper mySwiper5">
            <div class="row swiper-wrapper">
                <div class="sabc_social_box swiper-slide">
                    <div class="sabc_social_box_inr">
                        <a :href="this.$store.state.siteSetting.twitter" target="_blank">
                            <img :src="require('@/assets/images/sabc_social_twitter.svg')" alt="twitter" height="30" width="36" loading="lazy"/>
                            <b>
                                Twitter
                                <p>
                                    @SABC_Sport
                                </p>
                            </b>
                        </a>
                    </div>
                </div>
                <div class="sabc_social_box swiper-slide">
                    <div class="sabc_social_box_inr">
                        <a :href="this.$store.state.siteSetting.instagram" target="_blank">
                            <img :src="require('@/assets/images/sabc_social_instagram.svg')" alt="Instagram" height="36" width="36" loading="lazy"/>
                            <b>
                                Instagram
                                <p>
                                    @sabcsport
                                </p>
                            </b>
                        </a>
                    </div>
                </div>
                <div class="sabc_social_box swiper-slide">
                    <div class="sabc_social_box_inr">
                        <a :href="this.$store.state.siteSetting.facebook" target="_blank">
                            <img :src="require('@/assets/images/sabc_social_facebook.svg')" alt="Facebook" height="38" width="38" loading="lazy"/>
                            <b>
                                Facebook
                                <p>
                                    SABC Sport
                                </p>
                            </b>
                        </a>
                    </div>
                </div>
                <div class="sabc_social_box swiper-slide">
                    <div class="sabc_social_box_inr">
                        <a :href="this.$store.state.siteSetting.linkedin" target="_blank">
                            <img :src="require('@/assets/images/sabc_social_linkedin.svg')" alt="linkedin" height="36" width="36" loading="lazy"/>
                            <b>
                                LinkedIn
                                <p>
                                    SABC Sport
                                </p>
                            </b>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
    import { Swiper, Navigation } from "swiper";
    Swiper.use([ Navigation ]);
    export default {
        name: 'Social',
        data() {
            return {
            }
        },
        mounted() {
            new Swiper('.mySwiper5', {
                slidesPerView: 4,
                observer: true,
                observeParents: true,
                paginationClickable: true,
                spaceBetween: 18,
                autoplay: false,

                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                    },
                    800: {
                        slidesPerView: 3.5,
                    },
                    500: {
                        slidesPerView: 2.5,
                    },
                    200: {
                        slidesPerView: 1.5,
                    },
                }
            });
        },
        methods: {}
    }
</script>
