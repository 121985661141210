<template>
    <div>
        <div class="sabc_tier_news_tabs">
            <h2>Competitions</h2>
            <ul>
                <li v-for="(news, index) in tournamentNews">
                    <a style="cursor: pointer" :class="(index == selectedTab) ? 'selected' : ''" @click="(selectedTab = index)" href="javascript:;">
                        <span v-if="index == 'Premier Soccer League'">DSTV Premiership</span>
                        <span v-else>{{index}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="sabc_tier_news_sec" v-if="tournamentNews[selectedTab] && tournamentNews[selectedTab][0] && tournamentNews[selectedTab][0].articles && tournamentNews[selectedTab][0].articles.length > 0">
            <div class="sabc_tier_news_row" v-for="article in tournamentNews[selectedTab][0].articles">
                <div class="sabc_tier_news_pic">
                    <router-link :to="{ name: 'news_detail', params: { sport: article.sport.slug, news: 'news',slug:article.slug}}">
                        <img :src="$resizeImage(article.image.image_path, 368, 232)" :alt="article.image_caption" loading="lazy"/>
                    </router-link>
                </div>
                <div class="sabc_tier_news_desc">
                    <h2>
                        <router-link :to="{ name: 'news_detail', params: { sport: article.sport.slug, news: 'news',slug:article.slug}}">
                            {{article.headline}}
                        </router-link>
                    </h2>
                    <p v-html="article.stand_first">
                    </p>
                    <label>
                        {{article.created_at | formatDate2}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'TournamentNews',
        props: ['tournamentNews'],
        jsonld() {
            if (isServer)
                return {
                    "@context": "https://schema.org",
                    "@graph": []
                }

            if (!this.tournamentNews)
                return

            let events = [];
            Object.keys(this.tournamentNews).forEach((tournaments) => {
              if(this.tournamentNews[tournaments] && this.tournamentNews[tournaments][0] && this.tournamentNews[tournaments][0].articles){
                this.tournamentNews[tournaments][0].articles.forEach((article) => {
                  let path = this.$siteURL.replace(/\/+$/, '') + article.articleSection.section.slug+article.slug;
                  events.push({
                      '@type': 'NewsArticle',
                      mainEntityOfPage: {
                          '@type': 'WebPage',
                          '@id': path
                      },
                      headline:  article.headline,
                      description: article.meta_description,
                      image:  article.image.image_path,
                      author: {
                          '@type': 'Person',
                          name: article.author.display_name,
                          "url" : this.$siteURL
                      },
                      publisher: {
                          '@type': 'Organization',
                          name: "SABC",
                          logo: {
                              '@type': 'ImageObject',
                              url: this.$siteURL + 'public/images/logo.png'
                          }
                      },
                      datePublished: article.created_at,
                      dateModified: article.updated_at
                  })
                });
              }
            });
            return {
                "@context": "https://schema.org",
                "@graph": events
            };
        },
        data() {
            return {
                selectedTab: '',
            }
        },
        components: {
        },
        serverPrefetch() {
            let keys = Object.keys(this.tournamentNews);
            this.selectedTab = keys[0];
        },
        mounted() {
            let keys = Object.keys(this.tournamentNews);
            this.selectedTab = keys[0];
        },

        methods: {
        }
    }
</script>
