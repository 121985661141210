<template>
    <section class="sabc_news_section sabc_news_nhtopstory">
        <div class="container">
            <div class="row">
                <div v-for="article in news" class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12" :key="article.id">
                    <div class="sabc_news_box">
                        <a :href="article.link" v-if="article.link.startsWith('http')" target="_blank" class="sabc_news_pic">
                            <img 
                                :srcset="[
                                    $resizeImage(article.image.image_path, 295, 188) + ' 991w',
                                    $resizeImage(article.image.image_path, 361, 188) + ' 768w',
                                    $resizeImage(article.image.image_path, 144, 91) + ' 575w'
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 575w, (max-width: 575px) 575w, (max-width: 768px) 768w, (max-width: 991px)  991w'"
                                :src="$resizeImage(article.image.image_path, 144, 91)" 
                                width="295" 
                                height="188" 
                                :alt="article.headline" 
                                :title="article.headline" 
                                loading="lazy">
                            <label v-if="article.sport == null">News</label>
                            <label v-else-if="article.sport.name == 'Main Domain'">News</label>
                            <label v-else-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                        </a>
                        <router-link v-else :to="article.link" class="sabc_news_pic">
                            <img 
                                :srcset="[
                                    $resizeImage(article.image.image_path, 295, 188) + ' 991w',
                                    $resizeImage(article.image.image_path, 361, 188) + ' 768w',
                                    $resizeImage(article.image.image_path, 144, 91) + ' 575w'
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 575w, (max-width: 575px) 575w, (max-width: 768px) 768w, (max-width: 991px)  991w'"
                                :src="$resizeImage(article.image.image_path, 144, 91)" 
                                width="295" 
                                height="188" 
                                :alt="article.headline" 
                                :title="article.headline" 
                                loading="lazy">
                            <label v-if="article.sport == null">News</label>
                            <label v-else-if="article.sport.name == 'Main Domain'">News</label>
                            <label v-else-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                        </router-link>
                        <div class="sabc_news_desc">
                            <h3>
                                <a :href="article.link" v-if="article.link.startsWith('http')" target="_blank">{{article.headline}}</a>
                                <router-link v-else :to="article.link">{{article.headline}}</router-link>
                                <label v-if="article.sport == null">News</label>
                                <label v-else-if="article.sport.name == 'Main Domain'">News</label>
                                <label v-else-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'latest-news-row',
        jsonld() {
            if (isServer)
                return {
                    "@context": "https://schema.org",
                    "@graph": []
                }

            if (!this.news)
                return

            let events = [];
            this.news.forEach((article) => {
                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': article.link
                        },
                        headline:  article.headline,
                        description: article.blurb,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: "SABC",
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: '',
                        dateModified: ''
                    }
                )
            });
            return {
                "@context": "https://schema.org",
                "@graph": events
            };
        },
        props: ['news','type'],
        data() {
            return {}
        },
        mounted() {},
        methods: {}
    }
</script>
