<template>
    <section class="sabc_featured_area">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <div class="sabc_main_featured">
                        <a :href="topStories[0].link" v-if="topStories[0].link.startsWith('http')" target="_blank">
                            <img
                                :srcset="[
                                    `${$resizeImage(topStories[0].image.image_path, 798, 554)} 991w`,
                                    `${$resizeImage(topStories[0].image.image_path, 640, 534)} 768w`,
                                    `${$resizeImage(topStories[0].image.image_path, 320, 267)} 480w`
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 480w, (max-width: 768px) 768w, (max-width: 991px) 991w'"
                                :alt="topStories[0].image.caption || topStories[0].image.file_name"
                                :title="topStories[0].image.caption || topStories[0].image.file_name">
                            <div class="sabc_main_featured_dtl">
                                <div class="sabc_main_featured_desc">
                                    <label v-if="topStories[0].is_live">WATCH LIVE</label>
                                    <label class="ts-main-label" v-else>Top Stories</label>
                                    <h1>
                                        <span v-if="topStories[0].is_live">
                                           <img :src="require('@/assets/images/sabc_live_icon.webp')" alt="sabc_live_icon"/>
                                           LIVE
                                        </span>
                                        {{topStories[0].headline}}
                                    </h1>
                                </div>
                            </div>
                        </a>
                        <router-link  :to="topStories[0].link" v-else>
                            <img
                                :srcset="[
                                    `${$resizeImage(topStories[0].image.image_path, 798, 554)} 991w`,
                                    `${$resizeImage(topStories[0].image.image_path, 640, 534)} 768w`,
                                    `${$resizeImage(topStories[0].image.image_path, 320, 267)} 480w`
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 480w, (max-width: 768px) 768w, (max-width: 991px) 991w'"
                                :alt="topStories[0].image.caption || topStories[0].image.file_name"
                                :title="topStories[0].image.caption || topStories[0].image.file_name">
                            <div class="sabc_main_featured_dtl">
                                <div class="sabc_main_featured_desc">
                                    <label v-if="topStories[0].is_live">WATCH LIVE</label>
                                    <label class="ts-main-label" v-else>Top Stories</label>
                                    <h1>
                                        <span v-if="topStories[0].is_live">
                                           <img :src="require('@/assets/images/sabc_live_icon.webp')" alt="sabc_live_icon"/>
                                           LIVE
                                        </span>
                                    {{topStories[0].headline}}
                                    </h1>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div class="sabc_sub_featured" v-for="item in topStories.slice(1)" :key="item.id">
                        <a :href="item.link" v-if="item.link.startsWith('http')" target="_blank">
                           <img
                                :srcset="[
                                    `${$resizeImage(item.image.image_path, 400, 248)} 400w`,
                                    `${$resizeImage(item.image.image_path, 359, 248)} 359w`,
                                    `${$resizeImage(item.image.image_path, 270, 114)} 270w`,
                                    `${$resizeImage(item.image.image_path, 147, 114)} 147w`
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 147w, (max-width: 575px) 270w, (max-width: 768px) 359w, (max-width: 991px)  400w'"
                                :alt="item.image.caption || item.image.file_name"
                                :title="item.image.caption || item.image.file_name">
                            <div class="sabc_sub_featured_dtl">
                                <div class="sabc_sub_featured_desc">
                                    <label>Top Stories</label>
                                    <h2>{{item.headline}}</h2>
                                </div>
                            </div>
                        </a>
                        <router-link :to="item.link" v-else>
                            <img
                                :srcset="[
                                    `${$resizeImage(item.image.image_path, 400, 248)} 400w`,
                                    `${$resizeImage(item.image.image_path, 359, 248)} 359w`,
                                    `${$resizeImage(item.image.image_path, 270, 114)} 270w`,
                                    `${$resizeImage(item.image.image_path, 147, 114)} 147w`
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 147w, (max-width: 575px) 270w, (max-width: 768px) 359w, (max-width: 991px)  400w'"
                                :alt="item.image.caption || item.image.file_name"
                                :title="item.image.caption || item.image.file_name">
                            <div class="sabc_sub_featured_dtl">
                                <div class="sabc_sub_featured_desc">
                                    <label>Top Stories</label>
                                    <h2>
                                        {{item.headline}}
                                    </h2>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'TopStories',
        jsonld() {
            if (isServer)
                return {
                    "@context": "https://schema.org",
                    "@graph": []
                }

            if (!this.topStories)
                return

            let events = [];
            this.topStories.forEach((article) => {
                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': article.link
                        },
                        headline:  article.headline,
                        description: article.blurb,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: "SABC",
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: article.created_at,
                        dateModified: ''
                    }
                )
            });
            return {
                "@context": "https://schema.org",
                "@graph": events
            };
        },
        props: ['topStories'],
        data() {
            return {
            }
        },

        mounted() {
            this.preloadImages();
        },
        methods: {
            preloadImage(url) {
                const link = document.createElement('link');
                link.rel = 'preload';
                link.as = 'image';
                link.href = url;
                link.type = 'image/webp';
                document.head.appendChild(link);
            },
            preloadImages() {
                const imagePath = this.topStories[0].image.image_path;
                const width = window.innerWidth;
                // Conditional preloading based on screen width
                if (width >= 1024) {
                    this.preloadImage(this.$resizeImage(imagePath, 798, 554)); // Web/desktop size
                } else if (width >= 768) {
                    this.preloadImage(this.$resizeImage(imagePath, 640, 534)); // Tablet size
                } else {
                    this.preloadImage(this.$resizeImage(imagePath, 320, 267)); // Mobile size
                }
            },
        }
    }
</script>
