<template>
    <!--  article siderbar news start -->
    <div class="sabc_article_sidebar_news">
        <h2 v-if="editorsPick && editorsPick.length">Editors Pick<i></i></h2>
        <ul>
            <li v-for="item in editorsPick" :key="item.id">
                <a :href="item.link" v-if="item.link.startsWith('http')" target="_blank" class="sabc_article_sidebar_news_pic">
                    <img :src="$resizeImage(item.image.image_path,144,91)" width="144" height="91" :alt="item.headline" :title="item.headline" loading="lazy">
                </a>
                <router-link :to="item.link" v-else class="sabc_article_sidebar_news_pic">
                    <img :src="$resizeImage(item.image.image_path,144,91)" width="144" height="91" :alt="item.headline" :title="item.headline" loading="lazy">
                </router-link>
                <a :href="item.link" v-if="item.link.startsWith('http')" target="_blank" class="sabc_article_sidebar_news_desc">{{item.headline}}</a>
                <router-link :to="item.link" v-else class="sabc_article_sidebar_news_desc">
                    {{item.headline}}
                </router-link>
                
            </li>
        </ul>
        <router-link :to="'/olympics-table/'" aria-label="Olympics Table">
            <h2 v-if="olympicsTable" style="font-style: normal;font-weight: 600;font-size: 18px;line-height: 22px;color: #0C2251;margin-bottom: 17px;display: flex;align-items: center;align-content: center;flex-wrap: wrap;gap: 10px;">
                <img src="/public/images/olympic.png" alt="olympic logo" style="padding-right: 0px;width: 80px;height: auto;object-fit: cover;flex-shrink: 0;"> {{olympicsTable.headline}}<i></i>
            </h2>
        </router-link>
        <div v-if="olympicsTable" style="padding-bottom:8px; color:#7a7978;font-size: 13px;"> Date as at {{showCurrent()}}</div>
        <span style="height:755px; display:block; overflow:hidden" v-if="olympicsTable" v-html="olympicsTable.tableHTML"></span>
    </div>
    <!--  article siderbar news end -->
</template>
<script>

    import moment from 'moment';
    export default {
        name: 'SideBarNews',
        props: ['editorsPick', 'olympicsTable'],
        data() {
            return {}
        },
        mounted() {},
        methods: {
            showCurrent(){
                return moment().format("D MMM YYYY");
            }
        }
    }
</script>
